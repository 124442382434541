<template>
    <div class="account"> 
        <template v-if="isLoad && bankList.length > 0">
            <van-list v-model="loading" :finished="finished" @load="BankList">
                <ul class="account-wrap">
                    <li v-for="(item, index) in bankList" :key="index" @click="goToApply(item)">
                        <div class="account-wrap-r">
                            <div class="account-wrap-top">
                                <img src="@/assets/yhk.png" class="yhk-icon" alt />
                                <p class="bank">{{ item.bank_name }}</p>
                                <div class="account-wrap-top-r" @click.stop="">
                                    <p @click="Deletes(item.bank_id)">
                                        <i class="iconfont icon-shanchu"></i>&nbsp;&nbsp;删除
                                    </p>
                                </div>
                            </div>
                            <div class="account-wrap-bottom">
                                <p>{{ item.bank_card | filter }}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </van-list>
        </template>
        <no-data v-else content="这里空空的，快去添加吧"></no-data> 
        <div class="account-wrap-bott">
            <router-link to="/addBank">
                <p>添加银行卡</p>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {
        userBankList,
        userDeleteBankCard
    } from "@/http/api";
    //  import $ from "jquery";
    //  import {  Message} from "element-ui";
    export default {
        data() {
            return {
                userToken: "",
                pages: 1,
                bankList: [],
                
                bankcard: "",
                finished: true,
                isLoad: false,
                loading: false,
                from: 1,
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            if (this.$route.query.from) {
                this.from = this.$route.query.from;
            }
            this.BankList();
        },
        methods: {
            //获取用户银行卡信息
            async BankList() {
                let res = await userBankList({
                    data: {
                        user_token: this.userToken,
                        pages: this.pages
                    }
                }); 
                this.isLoad = true;
                this.loading = false;
                if (res.data.length > 0) {
                    this.pages++;
                    this.bankList = this.bankList.concat(res.data);
                    this.finished = true;
                } else {
                    this.finished = false;
                }
            },
            async Deletes(id) {
                let res = await userDeleteBankCard({
                    data: {
                        user_token: this.userToken,
                        bank_id: id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success("删除成功");
                    this.bankList = [];
                    this.pages = 1;
                    this.finished = true;
                    this.isLoad = false;
                    this.BankList();
                } else {
                    this.$toast.fail(res.msgs);
                }
            },
            goToApply(item) {
                if (this.from == "2") {
                    this.$store.dispatch("changeBankInfo", item);
                    this.$router.go(-1);
                }
            }
        },
        //过滤卡号，卡号前面用***表示
        filters: {
            filter(bank) {
                return bank.replace(/^(\d{4})\d+(\d{4})$/, "**** **** **** $2");
            }
        }
    };
</script>

<style lang="scss" scoped>
    .account {
        width: 100vw;
        min-height: 100vh;
        background: #f6f6f6;
        padding-bottom: 0.4rem;

        .van-list {
            margin-bottom: 2rem;
        }

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .account-wrap {
            width: calc(100% - 0.48rem);
            margin: 0 auto;
            padding-top: 0.2rem;

            li {
                display: flex;
                background: #fea579;
                padding: 0.36rem 0.28rem 0.37rem 0.39rem;
                box-sizing: border-box;
                border-radius: 0.1rem;
                margin-bottom: 0.3rem;
                color: #fff;

                .account-wrap-r {
                    width: 100%;
                }

                .yhk-icon {
                    width: 0.64rem;
                    height: 0.64rem;
                }

                .account-wrap-top {
                    width: 100%;
                    font-size: 0.23rem;
                    // margin-left: 0.28rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .bank {
                        font-size: 0.28rem;
                        width: calc(100% - 2.1rem);
                        font-size: 0.4rem;
                    }

                    .account-wrap-top-r {
                        width: 1.36rem;
                        text-align: right;
                        color: #fff;
                        font-size: 0.3rem;
                    }
                }

                .account-wrap-bottom {
                    font-size: 0.68rem;
                    margin-top: 0.4rem;
                    text-align: center;
                }
            }
        }

        .account-wrap-bott {
            width: calc(100% - 0.48rem);
            height: 0.84rem;
            background: #ff5400;
            color: #fff;
            line-height: 0.84rem;
            border-radius: 0.42rem;
            font-size: 0.3rem;
            margin-top: 5rem;
            text-align: center;
            margin: 0 auto;
            position: fixed;
            bottom: 15px;
            left: 0;
            right: 0;
        }
    }

    .more {
        font-size: 0.24rem;
        text-align: center;
    }

    .more-load {
        text-align: center;
        font-size: 0.24rem;
        color: #ff5400;
    }
</style>
